import base64 from "base-64";
import jwtDecode, {JwtPayload} from "jwt-decode";
import {DeviceInfoType} from "@nfl/rn-shared/src/types/deviceInfoTypes";
import {LocationCoordinates} from "@nfl/rn-shared/src/types/geolocationTypes";
import {NFLAPIConfig} from "../NFLAPIConfig/NFLAPIConfig";
import {asyncUnifiedResponse} from "../utils/asyncUnifiedResponse";

export const NFL_TOKEN_KEY = "nfl.refreshableToken.v3";
export const NFL_REFRESH_TOKEN_KEY = "nfl.refreshableToken.refreshToken.v3";
export const NFL_TOKEN_ARGS_KEY = "nfl.refreshableToken.args.v3";

export enum NFLRefreshableTokenRequestVariant {
    AMAZON_PRIME = "AMAZON_PRIME",
    CBS = "CBS",
    ESPN_PLUS = "ESPN_PLUS",
    MVPD = "MVPD",
    PEACOCK = "PEACOCK",
    USER = "USER",
    YOUTUBE = "YOUTUBE",
}

export type NFLTokenRequestType = {
    amazonPrimeUserId?: string;
    amazonPrimeUUID?: string;
    clientKey: string;
    clientSecret: string;
    customLocation?: LocationCoordinates;
    deviceId?: string;
    deviceInfo?: DeviceInfoType | string;
    espnPlusUserId?: string;
    espnPlusUUID?: string;
    identityProviderId?: string | null;
    location?: LocationCoordinates;
    mvpdIdp?: string;
    mvpdUserId?: string;
    mvpdUUID?: string;
    networkType?: string;
    paramountPlusUserId?: string;
    paramountPlusUUID?: string;
    peacockUserId?: string;
    peacockUUID?: string;
    refreshToken?: string | any;
    requireLocation?: boolean;
    signatureTimestamp?: string | null;
    skipPermissionRequests?: boolean;
    uid?: string;
    uidSignature?: string | null;
    youTubeUserId?: string;
    youTubeUUID?: string;
};

class NFLRefreshableTokenService {
    claimsEquals(a: string[] = [], b: string[] = []): boolean {
        if (!Array.isArray(a) || !Array.isArray(b) || a.length !== b.length) {
            return false;
        }
        const aSorted = a.sort();
        const bSorted = b.sort();
        for (let i = 0; i < aSorted.length; i += 1) {
            if (aSorted[i] !== bSorted[i]) {
                return false;
            }
        }
        return true;
    }

    // TODO: Remove this after we are settled on v3
    claimsFromParams(params: {
        amazonPrimeUserId?: string;
        espnPlusUserId?: string;
        mvpdUserId?: string;
        paramountPlusUserId?: string;
        peacockUserId?: string;
        youTubeUserId?: string;
        uid?: string;
    }): string[] {
        let nflClaimGroupsToAdd: string[] = [];
        if (params.uid) {
            nflClaimGroupsToAdd = [NFLRefreshableTokenRequestVariant.USER];
        }
        if (params.amazonPrimeUserId) {
            nflClaimGroupsToAdd.push(
                NFLRefreshableTokenRequestVariant.AMAZON_PRIME
            );
        }
        if (params?.espnPlusUserId) {
            nflClaimGroupsToAdd.push(
                NFLRefreshableTokenRequestVariant.ESPN_PLUS
            );
        }
        if (params.peacockUserId) {
            nflClaimGroupsToAdd.push(NFLRefreshableTokenRequestVariant.PEACOCK);
        }
        if (params.paramountPlusUserId) {
            nflClaimGroupsToAdd.push(NFLRefreshableTokenRequestVariant.CBS);
        }
        if (params.youTubeUserId) {
            nflClaimGroupsToAdd.push(NFLRefreshableTokenRequestVariant.YOUTUBE);
        }
        if (params.mvpdUserId) {
            nflClaimGroupsToAdd.push(NFLRefreshableTokenRequestVariant.MVPD);
        }
        return nflClaimGroupsToAdd;
    }

    getTokenRequestBody(tokenParams: NFLTokenRequestType) {
        return JSON.stringify({
            amazonPrimeUserId: tokenParams.amazonPrimeUserId || undefined,
            amazonPrimeUUID: tokenParams.amazonPrimeUUID || undefined,
            clientKey: tokenParams.clientKey,
            clientSecret: tokenParams.clientSecret,
            deviceId: tokenParams.deviceId,
            deviceInfo: tokenParams.deviceInfo
                ? base64.encode(JSON.stringify(tokenParams.deviceInfo))
                : undefined,
            espnPlusUserId: tokenParams.espnPlusUserId || undefined,
            espnPlusUUID: tokenParams.espnPlusUUID || undefined,
            identityProviderId: tokenParams.identityProviderId || undefined,
            mvpdIdp: tokenParams.mvpdIdp || undefined,
            mvpdUserId: tokenParams.mvpdUserId || undefined,
            mvpdUUID: tokenParams.mvpdUUID || undefined,
            networkType: tokenParams.networkType || undefined,
            paramountPlusUserId: tokenParams.paramountPlusUserId || undefined,
            paramountPlusUUID: tokenParams.paramountPlusUUID || undefined,
            peacockUserId: tokenParams.peacockUserId || undefined,
            peacockUUID: tokenParams.peacockUUID || undefined,
            signatureTimestamp: tokenParams.signatureTimestamp || undefined,
            uid: tokenParams.uid || undefined,
            uidSignature: tokenParams.uidSignature || undefined,
            youTubeUserId: tokenParams.youTubeUserId || undefined,
            youTubeUUID: tokenParams.youTubeUUID || undefined,
            ...(tokenParams.refreshToken
                ? {refreshToken: tokenParams.refreshToken}
                : {}),
            ...(tokenParams.location?.latitude
                ? {latitude: tokenParams.location?.latitude}
                : {}),
            ...(tokenParams.location?.longitude
                ? {longitude: tokenParams.location?.longitude}
                : {}),
        });
    }

    async getToken(
        tokenRequestParams: NFLTokenRequestType
    ): Promise<NFLTokenType> {
        const apiPath = NFLAPIConfig.authTokenBaseUrl || NFLAPIConfig.baseUrl;
        const url = `${apiPath}/identity/v3/token`.replace(/\s+/g, "");

        const body = this.getTokenRequestBody(tokenRequestParams);

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body,
        }).then(asyncUnifiedResponse);

        if (!response.accessToken) {
            throw response;
        }

        const {accessToken} = response;
        const decoded = jwtDecode<JwtPayload>(accessToken);

        return {
            ...decoded,
            ...response,
        };
    }

    async refreshToken(
        tokenRequestParams: NFLTokenRequestType
    ): Promise<NFLTokenType> {
        const apiPath = NFLAPIConfig.authTokenBaseUrl || NFLAPIConfig.baseUrl;
        const url = `${apiPath}/identity/v3/token/refresh`.replace(/\s+/g, "");
        const body = this.getTokenRequestBody(tokenRequestParams);

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body,
        }).then(asyncUnifiedResponse);

        if (!response.accessToken) {
            throw response;
        }

        const {accessToken} = response;
        const decoded = jwtDecode<JwtPayload>(accessToken);

        return {
            ...decoded,
            ...response,
        };
    }
}

export const NFLRefreshableToken = new NFLRefreshableTokenService();
