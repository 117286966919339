import {NFLAPIConfig} from "@nfl/nfl-api/src/NFLAPIConfig/NFLAPIConfig";
import {getOffers} from "./api/Offers";
import {
    OfferType,
    PlanBillingType,
    Grants,
    SUBSCRIPTION_PLAN_STATUS,
} from "../types";
import {COUNTRY_CODES} from "../constants/countryCodes";

// #region Grab Actual plan

/**
 * This function will give you a list of plans sorting trial plans and non-temp plans accordingly
 * if you don't find the first item in the list the plan that you want, it doesn't exist.
 *
 * @param token The NFL token.
 * @param planNames Plans to accept.
 * @param preferNonTrial Sort non-trial values ahead of trial values.
 * @param preferNonTemp Sort non-temp values ahead of temp values.
 * @param statuses Statuses to accept.
 * @param billingType Billing types to accept.
 */
export const getPlans = ({
    token,
    planNames = [],
    preferNonTrial = false,
    preferNonTemp = true,
    // Cancelled, Grace Period plans are still active for the remainder of the billing cycle.
    statuses = ["ACTIVE", "CANCELLED", "GRACE_PERIOD"],
    billingType,
}: {
    token?: NFLTokenType;
    planNames?: Array<string>;
    preferNonTrial?: boolean;
    preferNonTemp?: boolean;
    statuses?: Array<string>;
    billingType?: Array<PlanBillingType>;
}): NFLTokenPlanType[] => {
    const plans = (token?.plans || [])
        .filter((element) => {
            const hasValidPlan = planNames?.includes(
                element?.plan?.toUpperCase()
            );
            const hasValidStatus =
                element?.status && statuses.includes(element?.status);
            const hasValidBillingType = billingType?.includes(
                element?.billingType as PlanBillingType
            );
            const hasValidPlanAndStatus = hasValidPlan && hasValidStatus;

            return (
                (hasValidBillingType && hasValidPlanAndStatus) ||
                hasValidPlanAndStatus
            );
        })
        .sort((elementA, elementB) => {
            const elementATrial = elementA.trial;
            const elementBTrial = elementB.trial;
            if (preferNonTrial) {
                if (elementATrial !== elementBTrial) {
                    if (elementATrial) return 1;
                    if (elementBTrial) return -1;
                }
            }
            if (preferNonTemp) {
                if (elementA.source !== elementB.source) {
                    if (elementA.source === "tempGrant") return 1;
                    if (elementB.source === "tempGrant") return -1;
                }
            }
            return 0;
        });
    return plans;
};

export const getNFLPlusPlans = ({
    token,
    preferNonTrial = false,
    preferNonTemp = false,
}) => {
    return getPlans({
        token,
        planNames: ["NFL_PLUS"],
        preferNonTrial,
        preferNonTemp,
    });
};

export const getNFLPlusPlusPlans = ({
    token,
    preferNonTrial = false,
    preferNonTemp = false,
}) => {
    return getPlans({
        token,
        planNames: ["NFL_PLUS_PLUS"],
        preferNonTrial,
        preferNonTemp,
    });
};

export const getNFLPlusPremiumOrPlusPlusPlans = ({
    token,
    preferNonTrial = false,
    preferNonTemp = false,
}) => {
    return getPlans({
        token,
        planNames: ["NFL_PLUS_PREMIUM", "NFL_PLUS_PLUS"],
        preferNonTrial,
        preferNonTemp,
    });
};

export const getNFLPlusOrPlusPremiumOrPlusPlusPlans = ({
    token,
    preferNonTrial = false,
    preferNonTemp = false,
}) => {
    return getPlans({
        token,
        planNames: ["NFL_PLUS", "NFL_PLUS_PREMIUM", "NFL_PLUS_PLUS"],
        preferNonTrial,
        preferNonTemp,
    });
};

export const getPausedNFLPlusOrPlusPremiumPlans = ({
    token,
    preferNonTrial = false,
    preferNonTemp = false,
}) => {
    return getPlans({
        token,
        planNames: ["NFL_PLUS", "NFL_PLUS_PREMIUM"],
        preferNonTrial,
        preferNonTemp,
        statuses: [SUBSCRIPTION_PLAN_STATUS.PAUSED],
    });
};

export const getMonthlyPausedNFLPlusOrPlusPremiumPlans = ({
    token,
    preferNonTrial = false,
    preferNonTemp = false,
}) => {
    return getPlans({
        token,
        planNames: [Grants.NFL_PLUS, Grants.NFL_PLUS_PREMIUM],
        preferNonTrial,
        preferNonTemp,
        statuses: [SUBSCRIPTION_PLAN_STATUS.PAUSED],
        billingType: [PlanBillingType.monthly],
    });
};

export const getNFLPlusPausePlans = ({token}) => {
    return getPlans({
        token,
        planNames: ["NFL_PLUS"],
        statuses: [SUBSCRIPTION_PLAN_STATUS.PAUSED],
    });
};

export const getNFLPlusPremiumPausePlans = ({token}) => {
    return getPlans({
        token,
        planNames: ["NFL_PLUS_PREMIUM"],
        statuses: [SUBSCRIPTION_PLAN_STATUS.PAUSED],
    });
};

export const getNflActiveOrPausedPlansByToken = ({
    token,
}: {
    token: NFLTokenType;
}) => {
    const activePlans = getNFLPlusOrPlusPremiumOrPlusPlusPlans({token});
    const pausedPlans = getPausedNFLPlusOrPlusPremiumPlans({token});
    if (Array.isArray(activePlans) && activePlans.length) {
        return activePlans;
    }
    if (Array.isArray(pausedPlans) && pausedPlans.length) {
        return pausedPlans;
    }
    return [];
};

export const getRedzonePlans = ({
    token,
    preferNonTrial = false,
    preferNonTemp = false,
}) => {
    return getPlans({
        token,
        planNames: ["REDZONE"],
        preferNonTrial,
        preferNonTemp,
    });
};

// #endregion

/* #region StatusByToken */

export const getNFLPlusStatusByToken = (
    token?: NFLTokenType,
    onlyPermanentGrant = false
) => {
    const plan = getNFLPlusPlans({
        token,
        preferNonTemp: onlyPermanentGrant,
    })?.[0];
    if (onlyPermanentGrant) {
        return plan && plan?.source !== "tempGrant";
    }
    return !!plan;
};

export const getNFLPlusBillingType = async (token?: NFLTokenType) => {
    const nflToken = token || (await NFLAPIConfig.getAuthToken());
    const userHasNFLPlus = getNFLPlusStatusByToken(nflToken);
    if (userHasNFLPlus) {
        const currentPlan = nflToken?.plans.find((plan) => {
            return plan.plan === "NFL_PLUS";
        });
        return currentPlan?.billingType;
    }
    return "free";
};

export const getNFLPlusPremiumOrPlusPlusStatusByToken = (
    token?: NFLTokenType,
    onlyPermanentGrant = false
) => {
    const plan = getNFLPlusPremiumOrPlusPlusPlans({
        token,
        preferNonTemp: onlyPermanentGrant,
    })?.[0];
    if (onlyPermanentGrant) {
        return plan && plan?.source !== "tempGrant";
    }
    return !!plan;
};

export const getNFLPlusPlusStatusByToken = (
    token?: NFLTokenType,
    onlyPermanentGrant = false
) => {
    const plan = getNFLPlusPlusPlans({
        token,
        preferNonTemp: onlyPermanentGrant,
    })?.[0];
    if (onlyPermanentGrant) {
        return plan && plan?.source !== "tempGrant";
    }
    return !!plan;
};

export const getNFLPlusOrPlusPremiumOrPlusPlusStatusByToken = (
    token?: NFLTokenType,
    onlyPermanentGrant = false
) => {
    if (token) {
        const plan = getNFLPlusOrPlusPremiumOrPlusPlusPlans({
            token,
            preferNonTemp: onlyPermanentGrant,
        })?.[0];
        if (onlyPermanentGrant) {
            return plan && plan?.source !== "tempGrant";
        }
        return !!plan;
    }
    return false;
};

export const getNFLActivePlanOrPausedPlanStatusByToken = (
    token?: NFLTokenType
) => {
    if (token) {
        const plan = getNflActiveOrPausedPlansByToken({
            token,
        })?.[0];
        return !!plan;
    }
    return false;
};

export const getLapsedStatusByToken = (token?: NFLTokenType) => {
    return token?.plans?.some((plan) => plan?.status?.includes("TERMINATED"));
};

export const getNFLActivePlanOrPausedPlanStatusBySubscription = (
    subscriptionStatus?: any
) => {
    return (
        subscriptionStatus && ["ACTIVE", "PAUSED"].includes(subscriptionStatus)
    );
};

export const getRedZoneStatusByToken = (
    token?: NFLTokenType,
    onlyPermanentGrant = false
) => {
    const plan = getRedzonePlans({
        token,
        preferNonTemp: onlyPermanentGrant,
    })?.[0];
    if (onlyPermanentGrant) {
        return plan && plan?.source !== "tempGrant";
    }
    return !!plan;
};

export const getPlanStatusByToken = ({
    token,
    planName,
}: {
    token?: NFLTokenType;
    planName: string;
}) => {
    const plan = getPlans({
        token,
        planNames: [planName],
    })?.[0];
    return !!plan;
};

export const getParamountPlusStatusByToken = (token?: NFLTokenType) =>
    getPlanStatusByToken({token, planName: "PARAMOUNT_PLUS"});

export const getPeacockStatusByToken = (token?: NFLTokenType) =>
    getPlanStatusByToken({token, planName: "PEACOCK"});

export const getAmazonPrimeStatusByToken = (token?: NFLTokenType) =>
    getPlanStatusByToken({token, planName: "AMAZON_PRIME"});

export const getNetflixStatusByToken = (token?: NFLTokenType) =>
    getPlanStatusByToken({token, planName: "NETFLIX"});

export const getESPNPlusStatusByToken = (token?: NFLTokenType) =>
    getPlanStatusByToken({token, planName: "ESPN_PLUS"});

export const getNFLPlusPauseStatusByToken = (
    token?: NFLTokenType,
    forceTrue = false
) => {
    if (forceTrue) return true;

    const plan = getNFLPlusPausePlans({
        token,
    })?.[0];
    return !!plan;
};

export const getNFLPlusPremiumPauseStatusByToken = (
    token?: NFLTokenType,
    forceTrue = false
) => {
    if (forceTrue) return true;

    const plan = getNFLPlusPremiumPausePlans({
        token,
    })?.[0];
    return !!plan;
};

export const getUserHasPausedPlanByToken = (
    token: NFLTokenType,
    forceTrue = false
) => {
    if (forceTrue) return true;

    return (
        getNFLPlusPauseStatusByToken(token) ||
        getNFLPlusPremiumPauseStatusByToken(token)
    );
};

export const getMonthlyPlusPremiumPauseStatusByToken = (
    token,
    forceTrue = false
) => {
    if (forceTrue) return true;

    const plan = getMonthlyPausedNFLPlusOrPlusPremiumPlans({
        token,
    })?.[0];
    return !!plan;
};

export const getSundayTicketStatusByToken = (token?: NFLTokenType) => {
    const {networks = {}} = token || {};
    return Object.keys(networks).includes("NFLST");
};

export const getOffersByToken = async (
    appName: string,
    token?: NFLTokenType | null | undefined
) => {
    const authToken = token || (await NFLAPIConfig.getAuthToken());
    const offers = (await getOffers({
        authToken,
        available: appName,
    })) as unknown as OfferType[];

    const nflPlusPlan = getNFLPlusPlans({
        preferNonTemp: true,
        preferNonTrial: true,
        token: authToken,
    })?.[0];
    const nflPlusPremiumPlan = getNFLPlusPremiumOrPlusPlusPlans({
        preferNonTemp: true,
        preferNonTrial: true,
        token: authToken,
    })?.[0];

    return offers.filter((off) => {
        const {grant} = off.externalProperties;
        const billingType =
            off?.billingCycle?.periodUnit === "year" ? "annual" : "monthly";

        const matchesGrant =
            grant === nflPlusPlan?.plan || grant === nflPlusPremiumPlan?.plan;
        const matchesBillingType =
            billingType === nflPlusPlan?.billingType ||
            billingType === nflPlusPremiumPlan?.billingType;

        return matchesGrant && matchesBillingType;
    });
};

export const getUserIsDomesticByToken = ({token}: {token?: NFLTokenType}) => {
    return (
        getNFLPlusPlusStatusByToken(token) ||
        token?.countryCode === COUNTRY_CODES.US
    );
};

/* #endregion */
/* #region Status */

export const getNFLPlusStatus = async (forceTrue = false): Promise<boolean> => {
    if (forceTrue) return true;

    if (NFLAPIConfig.getAuthToken) {
        const authToken = await NFLAPIConfig.getAuthToken();
        return getNFLPlusStatusByToken(authToken);
    }
    return false;
};

export const getNFLPlusPremiumStatus = async ({
    forceTrue = false,
    onlyPermanentGrant = false,
} = {}): Promise<boolean> => {
    if (forceTrue) return true;

    if (NFLAPIConfig.getAuthToken) {
        const authToken = await NFLAPIConfig.getAuthToken();
        return getNFLPlusPremiumOrPlusPlusStatusByToken(
            authToken,
            onlyPermanentGrant
        );
    }
    return false;
};

export const getNFLPlusOrPlusPremiumOrPlusPlusStatus = async ({
    forceTrue = false,
    onlyPermanentGrant = false,
} = {}): Promise<boolean> => {
    if (forceTrue) return true;

    if (NFLAPIConfig.getAuthToken) {
        const authToken = await NFLAPIConfig.getAuthToken();
        return getNFLPlusOrPlusPremiumOrPlusPlusStatusByToken(
            authToken,
            onlyPermanentGrant
        );
    }
    return false;
};

export const getRedZoneStatus = async ({
    forceTrue = false,
    onlyPermanentGrant = false,
} = {}): Promise<boolean> => {
    if (forceTrue) return true;

    if (NFLAPIConfig.getAuthToken) {
        const authToken = await NFLAPIConfig.getAuthToken();
        return getRedZoneStatusByToken(authToken, onlyPermanentGrant);
    }
    return false;
};

export const getParamountPlusStatus = async (
    forceTrue = false
): Promise<boolean> => {
    if (forceTrue) return true;

    if (NFLAPIConfig.getAuthToken) {
        const authToken = await NFLAPIConfig.getAuthToken();
        return getParamountPlusStatusByToken(authToken);
    }
    return false;
};

export const getPeacockStatus = async (
    forceTrue?: boolean
): Promise<boolean> => {
    if (forceTrue) return true;

    if (NFLAPIConfig.getAuthToken) {
        const authToken = await NFLAPIConfig.getAuthToken();
        return getPeacockStatusByToken(authToken);
    }
    return false;
};

export const getAmazonPrimeStatus = async (
    forceTrue = false
): Promise<boolean> => {
    if (forceTrue) return true;

    if (NFLAPIConfig.getAuthToken) {
        const authToken = await NFLAPIConfig.getAuthToken();
        return getAmazonPrimeStatusByToken(authToken);
    }
    return false;
};

export const getSundayTicketStatus = async (
    forceTrue = false
): Promise<boolean> => {
    if (forceTrue) return true;

    if (NFLAPIConfig.getAuthToken) {
        const authToken = await NFLAPIConfig.getAuthToken();
        return getSundayTicketStatusByToken(authToken);
    }
    return false;
};

export const getESPNPlusStatus = async (
    forceTrue = false
): Promise<boolean> => {
    if (forceTrue) return true;

    if (NFLAPIConfig.getAuthToken) {
        const authToken = await NFLAPIConfig.getAuthToken();
        return getESPNPlusStatusByToken(authToken);
    }
    return false;
};

export const getNFLPlusPauseStatus = async (
    forceTrue = false
): Promise<boolean> => {
    if (forceTrue) return true;

    if (NFLAPIConfig.getAuthToken) {
        const authToken = await NFLAPIConfig.getAuthToken();
        return getNFLPlusPauseStatusByToken(authToken);
    }
    return false;
};

export const getNFLPlusPremiumPauseStatus = async ({
    forceTrue = false,
} = {}): Promise<boolean> => {
    if (forceTrue) return true;

    if (NFLAPIConfig.getAuthToken) {
        const authToken = await NFLAPIConfig.getAuthToken();
        return getNFLPlusPremiumPauseStatusByToken(authToken);
    }
    return false;
};

export const getUserHasPausedPlan = async (
    forceTrue = false
): Promise<boolean> => {
    if (forceTrue) return true;

    const plusPauseStatus = await getNFLPlusPauseStatus(forceTrue);

    if (plusPauseStatus) return true;

    const plusPremiumPauseStatus = await getNFLPlusPremiumPauseStatus({
        forceTrue,
    });

    return plusPremiumPauseStatus;
};

export const hasClaimByToken = ({
    providerName,
    token,
}: {
    providerName: string;
    token?: NFLTokenType;
}) => {
    return token?.claims?.includes(providerName);
};

/* #endregion */
