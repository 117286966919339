import * as React from "react";
import Link from "next/link";

// components
import {FantasyPlusLogoText} from "@nfl/rn-app-nfl-fantasy/src/components/project/FantasyPlusLogo/FantasyPlusLogoText";
import {Analytics} from "@nfl/rn-shared/src/containers/Analytics";
import {storage} from "@nfl/rn-shared/src/stores/localStorage";
import {trackingValues} from "@nfl/rn-app-nfl-fantasy/src/constants/trackingValues";
import CollapseSection from "./CollapseSection";
import NavItems from "./NavItems";
import UserContext from "./UserContext";

// constants
import {ROUTES} from "../constants/nav";
import {FEATURES} from "../constants/features";

// types
import type {NavItem} from "../types";

// utils
import {trackClick} from "../helpers/tracking";
import {getProfileUrl, getWebBaseUrl, replaceUrlParams} from "../utils/url";
import {removeFantasyCookie} from "../utils/cookie";
import {gigyaSignIn, gigyaSignOut} from "../utils/gigya";
import {getSelectedLeague, userHasFantasyPlus} from "../utils/user";
import {
    FANTASY_PLUS_ACCESS_POINT,
    FANTASY_PLUS_PURCHASE_ACCESS_POINT,
    TRACKING_SITE_NAME,
} from "../constants/tracking";

type NavHeaderProps = {
    location: string;
    pageName: string;
    pageParams?: Record<string, string>;
};

const CASUAL_GAMES = new Set([
    "knockout",
    // "numbers",
    // "perfectchallenge",
    "playoffchallenge",
    // "predictpick",
    // "survivor",
]);

// TODO: handle within react
const setUpMenuListeners = () => {
    let lastFocusedElement;
    const preventDefault = (e) => {
        e.preventDefault();
        return false;
    };

    const addEventListener = (el, event, handler) => {
        if (!el) return;
        if (el.addEventListener) {
            el.addEventListener(event, handler);
        } else if (el.attachEvent) {
            el.attachEvent(event, handler);
        }
    };

    const removeEventListener = (el, event, handler) => {
        if (!el) return;
        if (el.removeEventListener) {
            el.removeEventListener(event, handler);
        } else if (el.detachEvent) {
            el.detachEvent(event, handler);
        }
    };

    const arrow = document.getElementById("arrow-more");
    const mainSubNav = document.getElementById("main-subnav-container");
    const mobileMenuButton = document.getElementById("mobile-menu-button");
    const mobileMenu = document.getElementById("mobile-menu-container");
    const closeButton = document.getElementById("close-button");
    const more = document.getElementById("ellipse-more");
    const {body} = document;
    const maintainFocus = (event) => {
        if (!event.target.closest('[aria-modal="true"]')) {
            mobileMenu?.focus();
        }
    };

    const trapTabKey = (event) => {
        const focusableChildren = Array.from<any>(
            mobileMenu?.querySelectorAll(
                [
                    'a[href]:not([tabindex^="-"])',
                    'area[href]:not([tabindex^="-"])',
                    'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
                    'input[type="radio"]:not([disabled]):not([tabindex^="-"])',
                    'select:not([disabled]):not([tabindex^="-"])',
                    'textarea:not([disabled]):not([tabindex^="-"])',
                    'button:not([disabled]):not([tabindex^="-"])',
                    'iframe:not([tabindex^="-"])',
                    'audio[controls]:not([tabindex^="-"])',
                    'video[controls]:not([tabindex^="-"])',
                    '[contenteditable]:not([tabindex^="-"])',
                    '[tabindex]:not([tabindex^="-"])',
                ].join(",")
            ) || []
        ).filter(
            (child) =>
                child.offsetWidth ||
                child.offsetHeight ||
                child.getClientRects().length
        );
        const focusedItemIndex = focusableChildren.indexOf(
            document.activeElement
        );
        if (event.shiftKey && focusedItemIndex === 0) {
            focusableChildren[focusableChildren.length - 1].focus();
            event.preventDefault();
        } else if (
            !event.shiftKey &&
            focusedItemIndex === focusableChildren.length - 1
        ) {
            focusableChildren[0].focus();
            event.preventDefault();
        }
    };

    const bindKeypress = (event) => {
        if (
            document.activeElement?.closest('[aria-modal="true"]') !==
            mobileMenu
        ) {
            return;
        }

        if (event.key === "Escape") {
            event.preventDefault();
            closeMobileMenu();
        }
        if (event.key === "Tab") {
            trapTabKey(event);
        }
    };
    const clickHandler = () => {
        if (more) more.className += " ellipse-more-click";
        if (arrow) arrow.className = "arrow-more-grow";
        if (mainSubNav) {
            mainSubNav.className += " main-subnav-container-slide";
        }
    };

    const showEllipse = () => {
        if (more) more.className = "ellipse-more";
        if (arrow) arrow.className = "arrow-more";
        if (mainSubNav) mainSubNav.className = "main-subnav-container";
    };

    const showMobileMenu = () => {
        if (mobileMenu) {
            mobileMenu.className += " mobile-menu-container-show";
        }
        if (closeButton) closeButton.className += " close-button-grow";
        body.style.overflow = "hidden";
        body.style.height = "100%";
        if (body.parentNode) {
            // @ts-expect-error
            body.parentNode.style.overflow = "hidden";
            // @ts-expect-error
            body.parentNode.style.height = "100%";
        }
        lastFocusedElement = document.activeElement;
        mobileMenu?.removeAttribute("aria-hidden");
        mobileMenu?.focus();

        addEventListener(body, "focus", maintainFocus);
        addEventListener(mobileMenu, "keydown", bindKeypress);
        addEventListener(body, "touchmove", preventDefault);
    };

    const closeMobileMenu = () => {
        if (mobileMenu) mobileMenu.className = "mobile-menu-container";
        if (closeButton) closeButton.className = " close-button";
        body.style.overflow = "visible";
        body.style.height = "auto";
        if (body.parentNode) {
            // @ts-expect-error
            body.parentNode.style.overflow = "visible";
            // @ts-expect-error
            body.parentNode.style.height = "auto";
        }
        mobileMenu?.setAttribute("aria-hidden", "true");
        if (lastFocusedElement) {
            lastFocusedElement.focus();
        }
        removeEventListener(body, "focus", maintainFocus);
        removeEventListener(mobileMenu, "keydown", bindKeypress);
        removeEventListener(body, "touchmove", preventDefault);
    };

    window.gigya?.accounts.addEventHandlers({
        onLogout: () => {
            removeFantasyCookie();
            window.location.reload();
        },
    });

    addEventListener(more, "click", clickHandler);
    addEventListener(arrow, "click", showEllipse);
    addEventListener(mobileMenuButton, "click", showMobileMenu);
    addEventListener(closeButton, "click", closeMobileMenu);
};
// TODO: handle within react
const sizeWrapper = () => {
    const navContainer = document.querySelector("div.nav-container");
    const container = document.querySelector("div.nav-container-wrap");
    if (navContainer && container) {
        // @ts-expect-error
        container.style.height = `${navContainer.offsetHeight}px`;
    }
};

const NavHeader = (props: NavHeaderProps) => {
    const {location, pageName, pageParams} = props;
    const {leagueId, teamId} = pageParams || {leagueId: "", teamId: ""};

    const [profileUrl, setProfileUrl] = React.useState<string>();
    const [showFantasyPlusNotification, setShowFantasyPlusNotification] =
        React.useState(false);

    const match = /^([^/]*:?)\/\/([^/]*)(\/[^?]*)(\?|$)/.exec(location);
    const host = match?.[2]?.split(".");
    const path = match?.[3];

    const isStage = host?.[0] === "stage";
    const gameName = host?.[isStage ? 1 : 0] || "";
    const casualGame = CASUAL_GAMES.has(gameName);

    const {fetched, user} = React.useContext(UserContext);
    const {
        email,
        gameState,
        isImpersonatingUser,
        isLoggedIn,
        leagues,
        systemConfig,
        userId,
        week,
    } = user;
    const {isRegistrationOpen, isSeasonStarted} = gameState || {};
    const {isFeatureDraftCenterTabEnabled, isFeaturePreviewsEnabled} =
        systemConfig || {};
    const hasFantasyPlus = userHasFantasyPlus(user);
    const selectedLeague = getSelectedLeague(leagues, leagueId);
    const previousWeek = Number(week) - 1;
    const draftStatus = selectedLeague?.draftStatus || "predraft";
    const predraft = draftStatus === "predraft";
    const numKeeperPlayers = +(selectedLeague?.numKeeperPlayers || 0);
    const isTeamManager =
        !!leagueId &&
        !!teamId &&
        !!selectedLeague &&
        selectedLeague.id.toString() === leagueId.toString() &&
        selectedLeague.teamId.toString() === teamId.toString();
    const isLeagueManager = selectedLeague?.isManager || false;
    const participating = isLoggedIn && !!leagues?.length;

    const emailRef = React.useRef(email);

    React.useEffect(() => {
        // runs client-side only
        setProfileUrl(getProfileUrl());
        sizeWrapper();

        const key = "nfl.fantasyplus.notification";
        if (location.includes("/fantasyplus")) {
            storage.save({
                key,
                data: new Date().toISOString(),
                expires: null,
            });
        } else if (isSeasonStarted && emailRef.current !== email) {
            emailRef.current = email;

            storage.load({key, autoSync: false}).then((value) => {
                if (emailRef.current !== email) return;

                const dismissed = Date.parse(value) || 0;

                const time = new Date();
                const timeZone = "America/Los_Angeles";
                const tzDate = new Date(
                    time.toLocaleString("en-US", {timeZone})
                );
                const tzOffset = time.getTime() - tzDate.getTime();

                if (tzDate.getDay() !== 2 || tzDate.getHours() >= 8) {
                    // Last Tuesday
                    tzDate.setDate(
                        tzDate.getDate() - ((tzDate.getDay() + 4) % 7) - 1
                    );
                }
                tzDate.setHours(8);
                tzDate.setMinutes(0);
                tzDate.setSeconds(0);

                setShowFantasyPlusNotification(
                    dismissed < tzDate.getTime() + tzOffset
                );
            });
        }
    }, [location, isSeasonStarted, email]);

    React.useEffect(() => {
        sizeWrapper();
    });

    React.useEffect(() => {
        if (fetched) setUpMenuListeners();
    }, [fetched]);

    const showFantasyPlusButton =
        FEATURES["features.fantasyplus.navitem"] === "true" &&
        (hasFantasyPlus || FEATURES["features.fantasyplus.upsell"] === "true");

    const mainNavItems = [
        {label: "How To Play", url: "/howtoplay"},
        !!isFeatureDraftCenterTabEnabled &&
            !!isRegistrationOpen && {
                label: "Draft Center",
                pageName: "draftCenterHome",
            },
        {label: "Research", pageName: "researchHome"},
        {
            label: "Shop",
            target: "_blank",
            url: "https://www.nflshop.com/?_s=bm-nflcom-fantasy-hotbutton-2024",
        },
        // {
        //     label: "Survivor",
        //     gameName: "survivor",
        //     url: "https://survivor.fantasy.nfl.com/",
        // },
        // {
        //     label: "Knockout",
        //     gameName: "knockout",
        //     url: "https://knockout.nfl.com/?icampaign=kno-nav-snv-fantasy_web_nav_link",
        // },
        // {
        //     label: "Numbers",
        //     gameName: "numbers",
        //     url: "https://numbers.nfl.com/?icampaign=num-nav-snv-fantasy_web_nav_link",
        // },
        // {
        //     label: "Playoff Challenge",
        //     gameName: "playoffchallenge",
        //     url: "https://playoffchallenge.fantasy.nfl.com/",
        // },
        // {
        //     label: "Predict The Pick",
        //     gameName: "predictpick",
        //     url: "https://predictpick.nfl.com/",
        // },
    ];
    const subnavItems = {
        draftcenter: [
            {label: "Home", pageName: "draftCenterHome"},
            {label: "Breakdown", pageName: "draftCenterBreakdown"},
            {label: "Mock Drafts", pageName: "draftCenterMockDrafts"},
            {label: "Live Draft Lobby", pageName: "draftCenterLiveDraftLobby"},
            {label: "How to Draft", pageName: "draftCenterHelp"},
        ],
        fantasy: [
            {label: "Fantasy", pageName: participating ? "myLeagues" : "home"},
            participating && {label: "League", pageName: "leagueHome"},
            participating && {label: "My Team", pageName: "teamHome"},
            participating && {label: "Game Center", pageName: "teamMatchup"},
            participating && {label: "Players", pageName: "playersHome"},
            {
                label: "Help",
                url: "https://support.nfl.com/hc/en-us",
                target: "_blank",
            },
            participating &&
                isLeagueManager && {label: "Manage", pageName: "commishHome"},
        ],
        gamecenter: [
            {label: "Matchup", pageName: "teamMatchup"},
            !predraft && {
                label: "My Fantasy Scoreboard",
                pageName: "myScoreboard",
                target: "myscoreboard",
                targetFeatures:
                    "location=no,menubar=no,titlebar=no,height=820,width=1020,resizable=yes,scrollbars=yes,status=no",
            },
            {
                label: "Watch NFL Games",
                href: "https://gamepass.nfl.com/?redirected=true&icampaign=gpd-nav-Fantasy_GC_subnav_watchgames",
            },
            isFeaturePreviewsEnabled &&
                previousWeek > 0 && {
                    label: `Week ${previousWeek} Recap`,
                    pageName: "teamMatchupWeek",
                },
        ],
        home: [
            participating && {label: "My Leagues", pageName: "myLeagues"},
            {label: "Play Fantasy", pageName: "home"},
            {label: "Leaderboard", pageName: "leaderboard"},
        ],
        league: [
            {label: "Home", pageName: "leagueHome"},
            {label: "Email League", pageName: "leagueEmail"},
            {label: "Team Managers", pageName: "leagueManagers"},
            {label: "Depth Charts", pageName: "leagueDepthCharts"},
            {label: "Discussions", pageName: "leagueMessages"},
            {label: "Transactions", pageName: "leagueTransactions"},
            {label: "Fees", pageName: "leagueFees"},
            predraft && {label: "Draft Info", pageName: "leagueDraftInfo"},
            !predraft && {label: "Draft Recap", pageName: "leagueDraftResults"},
            // !predraft && {label: "Polls", pageName: "leaguePolls"},
            {label: "Settings", pageName: "leagueSettings"},
            selectedLeague?.leagueType === "private" && {
                label: "History",
                pageName: "leagueHistory",
            },
        ],
        players: [
            {label: "Player List", pageName: "playersHome"},
            {label: "Scoring Leaders", pageName: "playersLeaders"},
            {label: "Player News", pageName: "playersNews"},
            {label: "Injury Report", pageName: "playersInjuries"},
            {label: "Stat Corrections", pageName: "playersStatCorrections"},
        ],
        research: [
            {label: "Research", pageName: "researchHome"},
            {label: "Rankings", pageName: "researchRankings"},
            {
                label: "Fantasy Points Against",
                pageName: "researchPointsAgainst",
            },
            {label: "Projections", pageName: "researchProjections"},
            {label: "Scoring Leaders", pageName: "researchScoringLeaders"},
            {label: "Elite Performers", pageName: "researchElitePerformers"},
            {label: "Player Trends", pageName: "researchTrends"},
            {label: "Stat Corrections", pageName: "researchStatsCorrections"},
            {label: "Players", pageName: "researchPlayers"},
        ],
        team: [
            {label: "Roster", pageName: "teamHome"},
            isTeamManager &&
                !predraft && {label: "Add", pageName: "playersHome"},
            isTeamManager &&
                !predraft && {label: "Drop", pageName: "teamDropPlayer"},
            isTeamManager &&
                !predraft && {label: "Trade", pageName: "playersHomeTrade"},
            isTeamManager &&
                !predraft && {
                    label: "Waiver Report",
                    pageName: "teamWaiverReport",
                },
            isTeamManager && {label: "Watch List", pageName: "teamWatchList"},
            {label: "Transactions", pageName: "leagueTransactionsTeam"},
            isTeamManager && {
                label: "Trading Block",
                pageName: "teamTradingBlock",
            },
            isTeamManager &&
                numKeeperPlayers > 0 && {
                    label: "Keepers",
                    pageName: "teamKeepersEdit",
                },
            isTeamManager && {
                label: "Edit Settings",
                pageName: "teamSettingsEdit",
            },
            isTeamManager && {label: "Alerts", pageName: "teamAlerts"},
            // TODO: is this right?
            isTeamManager &&
                !predraft &&
                draftStatus !== "postdraft" && {
                    label: "Pre-Draft Rankings",
                    pageName: "teamPreRank",
                },
        ],
    };

    const selectedNavItem = mainNavItems.find((item) => {
        const prefix = (item || {}).pageName?.replace("Home", "");
        return prefix && pageName.startsWith(prefix);
    });

    const mainNav = mainNavItems.map((item: any) => {
        if (!item) return null;
        let {url} = item;

        let selected = false;
        if (selectedNavItem) {
            selected = item === selectedNavItem;
        } else {
            selected = casualGame
                ? gameName === item.gameName
                : url === "/howtoplay" && path !== "/";
        }

        if (item.pageName) {
            url = `${ROUTES[item.pageName].path}`;
        }

        if (casualGame && !item.gameName) {
            url = `${process.env.CORE_WEB_BASE_URL || ""}${url}`;
        }

        return (
            <span
                className={selected ? "nav-item selected" : "nav-item"}
                key={`header-main-nav-${item.label}`}
            >
                <a href={url} target={item.target}>
                    {item.label}
                </a>
            </span>
        );
    });
    const subnavs = ((!casualGame && ROUTES[pageName]?.subnavs) || []).map(
        ({name, selected}) => {
            const items = (subnavItems[name] || [])
                .filter(Boolean)
                .map((item: NavItem) => {
                    const route = ROUTES[item.pageName || ""];

                    if (!route) return item;

                    const url = replaceUrlParams(
                        route.href || route.path + (route.search || ""),
                        {
                            leagueId: selectedLeague
                                ? leagueId || selectedLeague.id
                                : "",
                            teamId:
                                // TODO: is this right?
                                !selectedLeague ||
                                (name === "fantasy" &&
                                    item.pageName === "teamHome")
                                    ? selectedLeague?.teamId || ""
                                    : teamId || selectedLeague.teamId,
                            userId,
                            weekId:
                                name === "gamecenter" &&
                                item.pageName === "teamMatchupWeek"
                                    ? previousWeek
                                    : week,
                        }
                    );

                    return {...item, url};
                });
            return {items, name, selected};
        }
    );

    return (
        <div className="nav-container-wrap" role="banner">
            {!!isImpersonatingUser && (
                <span className="impersonating">
                    Impersonating as : {email}
                </span>
            )}
            <div className="nav-container">
                <div
                    className={
                        hasFantasyPlus ? "content has-fantasy-plus" : "content"
                    }
                    role="navigation"
                >
                    <div className="left-container">
                        <span className="fantasy-logo">
                            <a href="/">
                                <img
                                    alt="NFL Fantasy Football"
                                    src="/static/images/svg/fantasy-logo.svg"
                                />
                            </a>
                        </span>
                    </div>
                    <div className="center-container">{mainNav}</div>
                    <div className="right-container">
                        {showFantasyPlusButton && (
                            <span className="nav-item">
                                <Link
                                    href="/fantasyplus"
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => {
                                        trackClick("click_action", {
                                            linkModule: "Fantasy Plus Header",
                                            linkPosition: "1:1",
                                            linkType: hasFantasyPlus
                                                ? FANTASY_PLUS_ACCESS_POINT
                                                : FANTASY_PLUS_PURCHASE_ACCESS_POINT,
                                            upsellType:
                                                "fantasy-plus-header-icon",
                                            upsellLocation:
                                                Analytics.shared.trackingName(
                                                    trackingValues.view.home,
                                                    TRACKING_SITE_NAME
                                                ),
                                            linkName: "Fantasy Plus Icon",
                                        });
                                    }}
                                >
                                    {showFantasyPlusNotification && (
                                        <div className="notification" />
                                    )}
                                    <span
                                        className={
                                            hasFantasyPlus
                                                ? "fantasy-plus-logo-subscribed"
                                                : "fantasy-plus-logo"
                                        }
                                    >
                                        <FantasyPlusLogoText
                                            subscribed={hasFantasyPlus}
                                        />
                                    </span>
                                </Link>
                            </span>
                        )}
                        {fetched && isLoggedIn && (
                            <>
                                <div className="nav-item">
                                    <a href={`${getWebBaseUrl()}/myleagues`}>
                                        My Leagues
                                    </a>
                                </div>
                                <div className="nav-item">
                                    <a
                                        aria-label="Profile"
                                        href={profileUrl ?? "#"}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <div className="profile-icon" />
                                    </a>
                                </div>
                            </>
                        )}
                        {fetched && !isLoggedIn && (
                            <div className="nav-item">
                                <button
                                    id="sign-in-action"
                                    type="button"
                                    onClick={gigyaSignIn}
                                >
                                    Sign In
                                </button>
                            </div>
                        )}
                        {!fetched && <div className="profile-loading" />}
                        <a href="https://www.nfl.com">
                            <img
                                alt="NFL"
                                className="nfl-logo"
                                src="https://static.www.nfl.com/image/upload/v1554321393/league/nvfr7ogywskqrfaiu38m.svg"
                            />
                        </a>
                    </div>
                    <div className="mobile-right-container">
                        <button
                            aria-controls="mobile-menu-container"
                            aria-expanded="false"
                            aria-label="Menu"
                            className="mobile-menu-button"
                            id="mobile-menu-button"
                            type="button"
                        >
                            ☰
                        </button>
                    </div>
                </div>
                {subnavs?.map(({items, name, selected}, i) => {
                    const id = i
                        ? "tertiary-nav-container"
                        : "secondary-nav-container";
                    return (
                        <div className={id} id={id} key={name}>
                            <NavItems items={items} pageName={selected} />
                            {name === "fantasy" && !!leagues.length && (
                                <div className="league-dropdown">
                                    <CollapseSection
                                        title={selectedLeague?.name}
                                    >
                                        {leagues.map((l) => (
                                            <div
                                                className={
                                                    selectedLeague?.id === l.id
                                                        ? "league-item selected"
                                                        : "league-item"
                                                }
                                                key={l.id}
                                            >
                                                <a
                                                    className="team-name"
                                                    href={l.teamUrl}
                                                >
                                                    <em>{l.teamName}</em>
                                                </a>
                                                <a
                                                    className="league-name"
                                                    href={l.url}
                                                >
                                                    <strong>{l.name}</strong>
                                                </a>
                                            </div>
                                        ))}
                                    </CollapseSection>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            <div
                aria-hidden="true"
                aria-label="Menu"
                aria-modal="true"
                className="mobile-menu-container"
                id="mobile-menu-container"
                role="dialog"
                tabIndex={-1}
            >
                <div className="header-content">
                    <div className="left-content">
                        {isLoggedIn && fetched ? (
                            <div className="sign-in-state-container">
                                <div className="mobile-profile-icon-container">
                                    <a
                                        aria-label="View Profile"
                                        href={profileUrl ?? "#"}
                                    >
                                        <div className="profile-icon" />
                                    </a>
                                </div>
                                <div className="username">{email}</div>
                                <div className="spacer" />
                                <div className="sign-in-state">
                                    <button
                                        aria-label="Sign Out"
                                        id="sign-out-action"
                                        type="button"
                                        onClick={gigyaSignOut}
                                    >
                                        Sign Out
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div style={{height: "100%"}}>
                                <div className="mobile-profile-icon-container">
                                    <button
                                        aria-label="Sign in"
                                        id="sign-in-action"
                                        type="button"
                                        onClick={gigyaSignIn}
                                    >
                                        <div className="profile-icon" />
                                    </button>
                                </div>
                                <div className="sign-in-state">
                                    <button
                                        aria-label="Sign in"
                                        id="sign-in-action"
                                        type="button"
                                        onClick={gigyaSignIn}
                                    >
                                        Sign In
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <button
                        aria-controls="mobile-menu-container"
                        aria-expanded="true"
                        aria-label="Close Menu"
                        className="close-button"
                        id="close-button"
                        type="button"
                    />
                </div>
                <div className="menu main-nav-container">
                    {mainNav}
                    <span className="nav-item">
                        <a href="https://nfl.com">NFL.com</a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default NavHeader;
