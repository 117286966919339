import * as React from "react";
import {Path, Svg} from "react-native-svg";

// constants
import {COLORS} from "../../../constants/colors";

type FantasyPlusLogoTextProps = {
    subscribed?: boolean;
    width?: number;
};

export const FantasyPlusLogoText = ({
    subscribed,
    width = 98,
}: FantasyPlusLogoTextProps) => {
    const letterColor = subscribed ? COLORS.MIDNIGHT : COLORS.WHITE;
    const plusColor = subscribed ? COLORS.WHITE : COLORS.SPRING_GREEN;
    return (
        <Svg
            fill="none"
            height={Math.round(width * (11 / 98))}
            viewBox="0 0 98 11"
            width={width}
        >
            <Path
                d="M0.483574 10.9722C0.0793241 10.9722 -0.0603259 10.8057 0.0254241 10.4241L2.25002 0.54815C2.33332 0.166526 2.54892 0 2.95317 0H10.1537C10.558 0 10.6976 0.166526 10.6119 0.54815L10.2027 2.36144C10.117 2.74306 9.90137 2.90959 9.49712 2.90959H5.38112L5.02342 4.4939H7.83357C8.23782 4.4939 8.37747 4.66043 8.29172 5.04205L7.88012 6.87153C7.79437 7.25315 7.57877 7.41968 7.17452 7.41968H4.36682L3.69062 10.4218C3.60487 10.8034 3.38927 10.9699 2.98502 10.9699H0.483574V10.9722Z"
                fill={letterColor}
            />
            <Path
                d="M13.2349 10.9722H10.8608C10.4566 10.9722 10.3169 10.8057 10.4027 10.4241L11.3092 6.39971C11.4464 5.78911 11.8114 5.02817 12.4876 3.96194L14.6706 0.501892C14.8813 0.152649 15.1728 0 15.6089 0H19.9503C20.3693 0 20.5947 0.152649 20.6437 0.501892L21.2684 3.96194C21.4644 5.02817 21.4865 5.79142 21.3493 6.39971L20.4428 10.4241C20.357 10.8057 20.1414 10.9722 19.7372 10.9722H17.2994C16.8633 10.9722 16.7065 10.8057 16.7923 10.4241L17.2725 8.29163H14.4207L13.9405 10.4241C13.8547 10.8057 13.6391 10.9722 13.2349 10.9722ZM15.2071 5.51619H17.7429L17.5052 3.1987C17.466 3.01598 17.3852 2.93966 17.2578 2.93966H16.8535C16.7237 2.93966 16.611 3.01598 16.4885 3.1987L15.2071 5.51619Z"
                fill={letterColor}
            />
            <Path
                d="M24.1006 10.9722C23.6964 10.9722 23.5567 10.8057 23.6425 10.4241L25.8671 0.54815C25.9528 0.166526 26.1684 0 26.5727 0H28.2509C28.6699 0 28.9051 0.106392 29.1109 0.41169L31.48 3.79542L32.2101 0.550463C32.2959 0.166527 32.5115 0 32.9157 0H35.3363C35.7406 0 35.8802 0.166526 35.7945 0.54815L33.5699 10.4241C33.4841 10.8057 33.2685 10.9722 32.8643 10.9722H30.4437C30.0394 10.9722 29.8998 10.8057 29.9855 10.4241L30.4069 8.55067L28.3563 5.39592L27.2244 10.4241C27.1386 10.8057 26.923 10.9722 26.5188 10.9722H24.1006Z"
                fill={letterColor}
            />
            <Path
                d="M39.2098 10.9722C38.8055 10.9722 38.6659 10.8057 38.7516 10.4241L40.4372 2.94196H38.0632C37.6589 2.94196 37.5193 2.77543 37.605 2.39381L38.0191 0.550454C38.1048 0.168831 38.3204 0.00230408 38.7247 0.00230408H47.2482C47.6525 0.00230408 47.7921 0.168831 47.7064 0.550454L47.2923 2.39381C47.2066 2.77543 46.991 2.94196 46.5867 2.94196H44.2127L42.5271 10.4241C42.4413 10.8057 42.2257 10.9722 41.8215 10.9722H39.2098Z"
                fill={letterColor}
            />
            <Path
                d="M50.203 10.9722H47.8289C47.4247 10.9722 47.285 10.8057 47.3708 10.4241L48.2773 6.39971C48.4145 5.78911 48.7795 5.02817 49.4557 3.96194L51.6387 0.501892C51.8494 0.152649 52.1409 0 52.577 0H56.9184C57.3374 0 57.5628 0.152649 57.6118 0.501892L58.2365 3.96194C58.4325 5.02817 58.4546 5.79142 58.3174 6.39971L57.4109 10.4241C57.3251 10.8057 57.1095 10.9722 56.7053 10.9722H54.2675C53.8314 10.9722 53.6746 10.8057 53.7604 10.4241L54.2406 8.29163H51.3839L50.9037 10.4241C50.8228 10.8057 50.6072 10.9722 50.203 10.9722ZM52.1752 5.51619H54.7085L54.4709 3.1987C54.4317 3.01598 54.3508 2.93966 54.2234 2.93966H53.8192C53.6893 2.93966 53.5766 3.01598 53.4541 3.1987L52.1752 5.51619Z"
                fill={letterColor}
            />
            <Path
                d="M60.7772 10.9722C60.3729 10.9722 60.2333 10.8057 60.319 10.4241L60.7208 8.64087C60.8066 8.25925 61.0222 8.09272 61.4264 8.09272H65.7678C66.0422 8.09272 66.2333 7.89382 66.2872 7.65097C66.3656 7.29941 66.2529 7.16295 65.9834 7.13288L63.6339 6.81371C62.1639 6.60092 61.3162 5.99033 61.7008 4.28343L62.061 2.68293C62.453 0.945963 63.9083 0 66.0055 0H70.5257C70.93 0 71.0696 0.166526 70.9839 0.54815L70.5747 2.36144C70.489 2.74306 70.2734 2.90959 69.8691 2.90959H66.1084C65.834 2.90959 65.6306 3.0923 65.5694 3.36754C65.5081 3.64277 65.6282 3.82548 65.8952 3.85555L68.2325 4.16085C69.7025 4.37363 70.5649 4.98423 70.1803 6.69113L69.825 8.29163C69.433 10.0286 67.963 10.9746 65.8805 10.9746H60.7772V10.9722Z"
                fill={letterColor}
            />
            <Path
                d="M75.3081 10.9722C74.8891 10.9722 74.7642 10.8057 74.8499 10.4241L75.2885 8.47435L73.522 5.34966C73.1619 4.72519 73.1178 4.41989 73.3309 3.47393L73.99 0.54815C74.0757 0.166526 74.2913 0 74.6956 0H77.1823C77.5866 0 77.7262 0.166526 77.6405 0.54815L77.077 3.04836C77.028 3.26114 76.9814 3.47393 77.077 3.68902L77.5719 4.78532C77.6429 4.96804 77.7238 5.04436 77.9002 5.04436H78.0619C78.2383 5.04436 78.3534 4.96804 78.5078 4.78532L79.4976 3.68902C79.6911 3.47624 79.7377 3.26346 79.7867 3.04836L80.3502 0.54815C80.4359 0.166526 80.6515 0 81.0558 0H83.4617C83.8659 0 84.0056 0.166526 83.9198 0.54815L83.2608 3.47393C83.0476 4.41989 82.8516 4.72288 82.2244 5.34966L79.0615 8.48823L78.6254 10.4241C78.5396 10.8057 78.324 10.9722 77.9198 10.9722H75.3081Z"
                fill={letterColor}
            />
            <Path
                d="M97.2087 7.25778H93.2691L92.4263 11H88.7023L89.5451 7.25778H85.6055L86.3968 3.74222H90.3364L91.1792 0H94.9032L94.0604 3.74222H98L97.2087 7.25778Z"
                fill={plusColor}
            />
        </Svg>
    );
};
