import {storage} from "@nfl/rn-shared/src/utils/mmkv";
import {generateGUID} from "@nfl/rn-shared/src/utils/generateGUID";

export const GUID_STORAGE_KEY = "nfl.login.guid";

export const getGUID = () => {
    let guid = storage.getString(GUID_STORAGE_KEY);
    if (!guid) {
        guid = generateGUID();
        storage.set(GUID_STORAGE_KEY, guid || "");
    }
    return guid;
};
