import {ReactNativeTracker} from "@convivainc/conviva-react-native-appanalytics";
import {GameStateEnum} from "@nfl/nfl-web/constants/analytics";
import {deepEqual} from "fast-equals";
import {AppState} from "react-native";
import {TRACK_ACTION} from "../constants/analyticConstants";
import {isCTVWeb} from "../utils/isCtvWeb";

// TODO
// https://github.com/firebase/firebase-js-sdk/issues/2244
// import analytics from "@react-native-firebase/analytics";

export type AnalyticsProperties = {
    extraProperties?: Record<string, any>;
    pageDetail: string;
    siteSection: string;
    siteSubsection: string;
    skipTrackingOnAppStateChange?: boolean;
    adBlock?: string;
    pageName?: string;
    siteName?: string;
};

export type AnalyticsActionProperties = {
    accountFields?: string;
    carouselName?: string;
    contentLocation?: string;
    contentPosition?: string;
    extraProperties?: {
        [key: string]:
            | string
            | number
            | undefined
            | boolean
            | Record<string, any>;
    };
    linkModule: string;
    linkName: string;
    linkType: string;
    linkPosition?: string;
    gameID?: string | null | undefined;
    gameState?: GameStateEnum | string;
    siteName?: string;
    action?: string;
    errorMessage?: string;
    upsellType?: string;
    upsellLocation?: string;
    page?: {
        [key: string]:
            | string
            | number
            | undefined
            | boolean
            | Record<string, any>;
    };
    pageName?: string;
    thumbnailType?: string;
};

export type AnalyticsPurchaseActionProperties = {
    category: string;
    currencyCode: string;
    extraProperties?: {
        [key: string]: string;
    };
    linkModule: string;
    linkName: string;
    linkType: string;
    paidPrice?: string;
    price?: string;
    product: string;
    quantity: number;
    skuPrice?: string;
};

export type ParsedAnalyticsProperties = {
    pageDetail: string;
    siteSection: string;
    siteSubsection: string;

    [key: string]: string;
};

export type ParsedAnalyticsActionProperties = {
    linkModule: string;
    linkName: string;
    linkType: string;

    [key: string]: string;
};

export type ParsedAnalyticsPurchaseActionProperties = {
    category: string;
    price: string;
    product: string;
    // @ts-expect-error FixMe
    quantity: number;

    [key: string]: string;
};

type WebAnalyticsProperties = {
    // @ts-expect-error FixMe
    page?: {
        [key: string]: string;
    };
    // @ts-expect-error FixMe
    user?: {
        [key: string]: string;
    };

    [key: string]: string;
};

type TrackActions = keyof typeof TRACK_ACTION;
export type TrackAction = (typeof TRACK_ACTION)[TrackActions];

export interface Trackable {
    viewProperties: AnalyticsProperties;
}

export class Analytics {
    static _shared: Analytics | null | undefined = null;

    static userPropertyKeys = [
        "connection",
        "favoriteTeam",
        "gigyaUID",
        "hashedEmail",
        "hashedProfileId",
        "leagueID",
        "leagueManager",
        "leagueType",
        "playerType",
        "profileId",
        "teamID",
        "userID",
    ];

    convivaTracker: ReactNativeTracker | undefined;

    previousTrackingData: any;

    previousViewPropertiesTrackingData: any;

    siteName: string = isCTVWeb() ? "connected tv" : "";

    isTrackingEnabled: boolean = true;

    appState = AppState.currentState;

    writeToLocalLogFile?: (event: any) => void;

    _computeBaseProperties: () => Promise<{
        [key: string]: string;
    }> = () => Promise.resolve({});

    static get shared(): Analytics {
        if (!this._shared) {
            this._shared = new Analytics();
            return this._shared;
        }
        return this._shared;
    }

    initialize({
        computeBaseProperties,
        convivaTracker,
        isAppStateBeingListened = true,
        isTrackingEnabled = true,
        siteName,
        writeToLocalLogFile,
    }: {
        computeBaseProperties: () => Promise<{
            [key: string]: string;
        }>;
        convivaTracker?: ReactNativeTracker;
        isTrackingEnabled?: boolean;
        isAppStateBeingListened?: boolean;
        siteName: string;
        writeToLocalLogFile?: (event: any) => void;
    }) {
        this.convivaTracker = convivaTracker;
        this.siteName = siteName;
        this.isTrackingEnabled = isTrackingEnabled;
        this._computeBaseProperties = computeBaseProperties;
        this.writeToLocalLogFile = writeToLocalLogFile;
        if (isAppStateBeingListened) {
            this.appState = AppState.currentState;
            AppState.addEventListener("change", this._handleAppStateChange);
        }
    }

    // This mock function is to please the linter
    enableFirebaseAnalyticsIfUserIsInUS = async () => {};

    get baseProperties(): Promise<{
        [key: string]: string;
    }> {
        return this._computeBaseProperties();
    }

    static sanitizeValue({
        value,
        firebaseAnalytics,
    }: {
        firebaseAnalytics?: boolean;
        value: any;
    }) {
        if (value === null || value === undefined) {
            return "";
        }
        if (typeof value === "object") {
            if (firebaseAnalytics) {
                return JSON.stringify(value);
            }
            return Analytics.sanitizeProperties({
                firebaseAnalytics,
                properties: value,
            });
        }
        if (typeof value === "string") {
            return firebaseAnalytics
                ? value.replace(/\W+/g, "_").substring(0, 32)
                : value;
        }
        return `${value}`;
    }

    static sanitizeProperties({
        firebaseAnalytics,
        properties,
    }: {
        firebaseAnalytics?: boolean;
        properties?: any;
    }): any {
        return Object.keys(properties).reduce(
            (prevProperties: WebAnalyticsProperties, key) => {
                const value = Analytics.sanitizeValue({
                    value: properties[key],
                    firebaseAnalytics,
                });

                if (!firebaseAnalytics) {
                    const propertySection = Analytics.userPropertyKeys.includes(
                        key
                    )
                        ? "user"
                        : "page";

                    return {
                        ...prevProperties,
                        [propertySection]: {
                            ...(prevProperties[propertySection] || {}),
                            [key]: value,
                        },
                    };
                }

                return {
                    ...prevProperties,
                    [key]: value,
                };
            },
            {}
        );
    }

    static firebaseName(name: string): string {
        return name.replace(/\W+/g, "_").substring(0, 32);
    }

    async viewProperties({
        firebaseAnalytics,
        properties,
    }: {
        firebaseAnalytics?: boolean;
        properties: AnalyticsProperties;
    }): Promise<WebAnalyticsProperties> {
        return Analytics.sanitizeProperties({
            firebaseAnalytics,
            properties: {
                ...(await this.baseProperties),
                ...(properties.extraProperties || {}),
                siteName:
                    this.siteName.length === 0
                        ? properties.siteName
                        : this.siteName,
                siteSection: properties.siteSection,
                siteSubsection: properties.siteSubsection,
                pageDetail: properties.pageDetail,
            },
        });
    }

    async actionProperties({
        firebaseAnalytics,
        properties,
    }: {
        firebaseAnalytics?: boolean;
        properties: AnalyticsActionProperties;
    }): Promise<WebAnalyticsProperties> {
        return Analytics.sanitizeProperties({
            firebaseAnalytics,
            properties: {
                ...(await this.baseProperties),
                ...(properties.extraProperties || {}),
            },
        });
    }

    async purchaseActionProperties({
        firebaseAnalytics,
        properties,
    }: {
        firebaseAnalytics?: boolean;
        properties: AnalyticsPurchaseActionProperties;
    }): Promise<ParsedAnalyticsPurchaseActionProperties> {
        const paidPrice = properties.paidPrice ?? properties.price;
        const skuPrice = properties.skuPrice ?? properties.price;
        return Analytics.sanitizeProperties({
            firebaseAnalytics,
            properties: {
                ...(properties.extraProperties || {}),
                linkModule: properties.linkModule,
                linkName: properties.linkName,
                linkType: properties.linkType,
                category: properties.category,
                product: properties.product,
                paidPrice,
                quantity: String(properties.quantity),
                skuPrice,
                currencyCode: properties.currencyCode,
                "&&products": `${properties.category};${properties.product};${properties.quantity};${skuPrice}`,
            },
        });
    }

    trackingName(properties: AnalyticsProperties, siteName: string): string {
        const {siteSection, siteSubsection, pageDetail} = properties;
        const santitizedSiteName =
            siteName.length === 0 ? properties.siteName : siteName;
        return `${santitizedSiteName}:${siteSection}:${siteSubsection}:${pageDetail}`;
    }

    trackingActionName(properties: AnalyticsActionProperties): string {
        const {linkModule, linkName, linkType} = properties;
        return `${linkModule}:${linkName}:${linkType}`;
    }

    async trackViewComponent(component: Trackable) {
        await this.trackView(component.viewProperties);
    }

    async _trackView(trackingData: any, viewProperties: any) {
        const pageName = this.trackingName(trackingData, this.siteName);
        const {page, ...rest} = viewProperties;

        if (typeof window !== "undefined") {
            const data = {
                ...rest,
                ...page,
                pageName,
            };

            // @ts-expect-error FixMe
            window.analyticsData = data;

            if (window._satellite) {
                window._satellite.track("virtual_pv", data);
            }
        }
        this.logEvent(pageName, viewProperties);
    }

    async trackView(
        properties: AnalyticsProperties,
        forceTrack: boolean = false
    ) {
        if (!this.isTrackingEnabled) {
            return;
        }

        if (!deepEqual(properties, this.previousTrackingData) || forceTrack) {
            this.previousTrackingData = properties;
            const viewProperties = await this.viewProperties({properties});
            await this._trackView(properties, viewProperties);
        }
    }

    /**
     * Similar to trackView function above, except this does a deepEqual comparison
     * against the computed viewProperties, not just the provided tracking data.
     * This was required for screens that had a static set of screen-level tracking
     * data that doesn't change, but something else in the computed view properties
     * was that part that needed to be different.  We would lose subsequent trackView
     * calls unless this comparison was based off the computed data.
     */
    async trackViewWithRecomputedPropertiesCheck(
        properties: AnalyticsProperties,
        forceTrack: boolean = false
    ) {
        const viewProperties = await this.viewProperties({properties});

        if (
            !deepEqual(
                viewProperties,
                this.previousViewPropertiesTrackingData
            ) ||
            forceTrack
        ) {
            this.previousViewPropertiesTrackingData = viewProperties;
            this._trackView(properties, viewProperties);
        }
    }

    async trackAction(
        properties: AnalyticsActionProperties,
        action?: TrackAction
    ) {
        if (!this.isTrackingEnabled) {
            return;
        }

        if (window._satellite) {
            const name = this.trackingActionName(properties);
            const actionProperties = await this.actionProperties({properties});
            const data = {
                ...actionProperties,
                ...properties,
            };
            if (typeof window !== "undefined" && window._satellite) {
                window._satellite.track(
                    action ?? TRACK_ACTION.CLICK_ACTION,
                    data
                );
            }
            this.logEvent(name, data);
        }
    }

    async trackPurchaseAction(
        name: string,
        properties: AnalyticsPurchaseActionProperties
    ) {
        if (!this.isTrackingEnabled) {
            return;
        }

        const actionProperties = await this.purchaseActionProperties({
            properties,
        });
        if (typeof window !== "undefined" && window._satellite) {
            window._satellite.track(name, actionProperties);
        }
        this.logEvent(name, properties);
    }

    async trackError(properties: AnalyticsProperties, linkModule: string) {
        const linkName = this.trackingName(properties, this.siteName);
        await this.trackAction({
            linkModule,
            linkName,
            linkType: "page error",
        });
    }

    logCrash(error: any) {
        const errorMessage = JSON.stringify(error);
        // eslint-disable-next-line no-console
        console.log(errorMessage);
    }

    logEvent(name: string, properties?: any) {
        const firebaseName = Analytics.firebaseName(name);
        const firebaseProperties = Analytics.sanitizeProperties({
            firebaseAnalytics: true,
            properties: {
                ...(properties.user || {}),
                ...(properties.page || {}),
            },
        });
        // analytics().logEvent(firebaseName, firebaseProperties);
        // eslint-disable-next-line no-console
        console.log(firebaseName, firebaseProperties);
    }

    clearPreviousTrackingData = () => {
        this.previousTrackingData = null;
        this.previousViewPropertiesTrackingData = null;
    };

    retriggerPageTracking = () => {
        if (this.previousTrackingData) {
            this.trackView(this.previousTrackingData, true);
        }
    };

    _handleAppStateChange = (nextAppState) => {
        if (
            this.appState.match(/inactive|background/) &&
            nextAppState === "active" &&
            this.previousTrackingData
        ) {
            this.trackView(this.previousTrackingData, true);
            this.logEvent("APP_STATE", {foreground: true});
        } else if (
            nextAppState.match(/inactive|background/) &&
            this.appState === "active"
        ) {
            this.logEvent("APP_STATE", {foreground: false});
        }
        this.appState = nextAppState;
    };
}
