import defer from "defer-promise";
import hash from "object-hash";
import {data} from "../../../Connected/src/components/EventsWrapper/data";
import {loadFromCache, saveToCache} from "../stores/cacheStorage.native";

const syncedPromises: {
    [key: string]: {
        [key: string]: Promise<any>;
    } | null;
} = {};
/* Function basically makes sure that key `syncParams` aren't the same
 * before assuming they are the same call and consolidating it, the reason
 * we are atomically allowing access to token API calls is because token/refresh is not
 * idempodent and will fail when fetched multiple times with the same refreshToken.
 * if determined via syncParams that the calls are different, we probably invalidated the login
 * and need to wait for other calls to happen before automatically signing the user off.
 */
export const synchronizedPromise = (
    key: string,
    syncParams: any,
    promiseFunction: () => Promise<any>
): Promise<any> => {
    const hashedParams = syncParams ? hash(syncParams) : "";
    const cachedPromises = syncedPromises[key];

    if (cachedPromises?.[hashedParams]) {
        return cachedPromises?.[hashedParams];
    }

    const promise = promiseFunction().finally(() => {
        delete syncedPromises[key]?.[hashedParams];
    });

    syncedPromises[key] = {
        ...cachedPromises,
        [hashedParams]: promise,
    };

    return promise;
};

// ONLY works natively right now bc it uses cacheStorage.native
export const synchronizedCachedPromise = async (
    key: string,
    syncParams: any,
    expires: number,
    promiseFunction: () => Promise<any>
): Promise<any> => {
    // hash might not be perfect for this because {someParam: null} !== {someParam: undefined} and it's
    // probably rolls into the same results.
    const hashedParams = syncParams ? hash(syncParams) : "";
    const hashedKey = `${key}-${hashedParams}`;

    const cachedData = loadFromCache(hashedKey);
    if (cachedData) {
        return Promise.resolve(cachedData.data);
    }

    const promise = synchronizedPromise(
        hashedKey,
        syncParams,
        promiseFunction
    ).then((data) => {
        saveToCache(
            hashedKey,
            {
                hashedParams,
                data,
            },
            expires
        );
        return data;
    });
    return promise;
};

// sometimes you want to know the results of a promise without having to start the promise
// yourself. IE 2 listeners to the result of 1 promise.
export const forwardedPromise = <T>(promise: Promise<T>): Promise<T> => {
    const deferred = defer<T>();
    promise
        .then((value) => {
            deferred.resolve(value);
            return value;
        })
        .catch((e) => {
            deferred.reject(e);
            throw e;
        });
    return deferred.promise;
};

export const timeout = (promise: Promise<any>, time: number) => {
    return Promise.race([
        promise,
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Operation timed out")), time)
        ),
    ]);
};
