import {GeolocationOptions} from "../types/geolocationTypes";

export const getCurrentLocation = (
    options: GeolocationOptions | null | undefined
): Promise<{
    latitude: number;
    longitude: number;
}> => {
    return new Promise((resolve, reject) => {
        if ("geolocation" in window.navigator) {
            window.navigator.geolocation.getCurrentPosition(
                ({coords: {latitude, longitude}}) => {
                    resolve({latitude, longitude});
                },
                (error) => {
                    reject(error);
                },
                options ?? {}
            );
        } else {
            reject(new Error("geolocation IS NOT available"));
        }
    });
};

export const warmUpGeolocation = () => {
    // don't think we need to warmup for web
};
