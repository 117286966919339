import queryString from "query-string";
import mapValues from "object.map";
import {axiosInstance} from "@nfl/nfl-api/src/axiosInstance";
import {formatBody as encodeBody} from "@nfl/rn-shared/src/utils/formatBody";
import type {RegisterUserParams} from "./Gigya";

// The GigyaSDK provides an analogous plugin to GigyaModule for
// platforms(non-ios/non-android)
// that do not have their own gigyaSDK using gigya's REST API
// https://developers.gigya.com/display/GD/REST+API
export class GigyaRestSDK {
    static _shared: GigyaRestSDK | null | undefined;

    static get shared(): any {
        if (!this._shared) {
            this._shared = new GigyaRestSDK();
            return this._shared;
        }
        return this._shared;
    }

    apiKey: string;

    apiDomain: string;

    apiSecret: string;

    userKey: string;

    uid: string = "";

    regToken: string = "";

    initSDK({
        apiDomain = "",
        apiKey = "",
        apiSecret = "",
        userKey = "",
    }: {
        apiDomain?: string;
        apiKey?: string;
        apiSecret?: string;
        userKey?: string;
    }): Promise<void> {
        this.apiKey = apiKey;
        this.apiDomain = apiDomain;
        this.apiSecret = apiSecret;
        this.userKey = userKey;
        return Promise.resolve();
    }

    url(path: string, domain?: string): string {
        return `https://${domain ?? "accounts"}.${this.apiDomain}/${path}`;
    }

    async fetch({
        body,
        method,
        params,
        path,
        headers,
        domain,
    }: {
        body?: any;
        domain?: string;
        headers?: any;
        method?: "GET" | "POST";
        params?: any;
        path: string;
    }): Promise<any | null> {
        // query
        const queryParams = mapValues(params || {}, (value) => {
            if (typeof value === "boolean") {
                return value ? "1" : "0";
            }
            return value;
        });
        Object.keys(queryParams).forEach(
            (key) => queryParams[key] == null && delete queryParams[key]
        );
        let query = queryString.stringify(queryParams);
        query = query ? `&${query}` : "";

        // url
        const computedUrl = `${this.url(path, domain)}?apiKey=${
            this.apiKey
        }${query}`;

        const init = {
            headers: {
                "Content-Type": "application/json",
                ...headers,
            },
            method: method ?? "GET",
            data: body,
        };

        return axiosInstance
            .request({
                url: computedUrl,
                ...init,
            })
            .then((response) => {
                return response?.data;
            })
            .catch(() => {
                return null;
            });
    }

    isSessionValid(): Promise<void> {
        return Promise.resolve();
    }

    clearSession(): Promise<void> {
        return Promise.resolve();
    }

    async login(loginId: string, password: string): Promise<any | null> {
        const body = encodeBody({
            apiKey: this.apiKey,
            secret: this.apiSecret,
            userKey: this.userKey,
            include: "loginIDs,profile,subscriptions,data",
            loginID: loginId,
            password,
        });
        const response = await this.fetch({
            path: "accounts.login",
            body,
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
        });
        if (response?.UID) {
            this.uid = response.UID;
        }
        return response;
    }

    async getAccount(
        params: {
            uid?: string;
        } | null
    ): Promise<any> {
        const {uid} = params || {};

        if (!this.uid && !uid) {
            return null;
        }
        return this.fetch({
            path: "accounts.getAccountInfo",
            params: {
                apiKey: this.apiKey,
                secret: this.apiSecret,
                userKey: this.userKey,
            },
            body: encodeBody({
                UID: this.uid || uid,
                include: "loginIDs,profile,subscriptions,data,preferences",
            }),
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
        });
    }

    async setAccount(params: any): Promise<any | null> {
        const {uid, data} = params || {};

        if (!this.uid && !uid) {
            return null;
        }
        return this.fetch({
            path: "accounts.setAccountInfo",
            params: {
                ...params,
                apiKey: this.apiKey,
                data: JSON.stringify(data) || "{}",
                secret: this.apiSecret,
                UID: this.uid || uid,
                userKey: this.userKey,
            },
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
        });
    }

    /*
        Response example:
        {
            "callId": "dc56e1db83a74f3ebb426b4161bc3f89",
            "errorCode": 0,
            "apiVersion": 2,
            "statusCode": 200,
            "statusReason": "OK",
            "time": "2021-08-10T16:50:39.700Z",
            "registeredTimestamp": 1628614239,
            "UID": "47d9b8116c3d48baa9411e9754aa935f",
            "created": "2021-08-10T16:50:39.229Z",
            "createdTimestamp": 1628614239,
            "subscriptions": {
                "global": {
                    "email": {
                        "isSubscribed": true,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4128539Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "nfl_fantasy": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4128539Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "team_recap": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4128539Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "nfl_products": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4128539Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "daily_newsletter": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4128539Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "nfl_news": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4128539Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "nfl_programming": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4128539Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "nfl_network_weekly": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4138554Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "nfl_partner_offers": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4138554Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "nfl_events": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4138554Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "nfl_network_program": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4138554Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "nfl_mobile": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4138554Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                },
                "nfl_game_pass": {
                    "email": {
                        "isSubscribed": false,
                        "lastUpdatedSubscriptionState": "2021-08-10T16:50:39.4138554Z",
                        "tags": null,
                        "doubleOptIn": {
                            "confirmTime": null,
                            "emailSentTime": null,
                            "status": "NotConfirmed"
                        }
                    }
                }
            },
            "emails": {
                "verified": [],
                "unverified": [
                    "gigyatest5@gigyatest.com"]
            },
            "isActive": true,
            "isRegistered": true,
            "isVerified": false,
            "lastLogin": "2021-08-10T16:50:39.638Z",
            "lastLoginTimestamp": 1628614239,
            "lastUpdated": "2021-08-10T16:50:39.550Z",
            "lastUpdatedTimestamp": 1628614239550,
            "loginProvider": "site",
            "oldestDataUpdated": "2021-08-10T16:50:39.229Z",
            "oldestDataUpdatedTimestamp": 1628614239229,
            "profile": {
                "firstName": "Andrey V",
                "lastName": "Badaev",
                "age": 26,
                "birthDay": 4,
                "birthMonth": 6,
                "birthYear": 1995,
                "country": "USA",
                "email": "gigyatest5@gigyatest.com",
                "zip": "410044"
            },
            "registered": "2021-08-10T16:50:39.550Z",
            "socialProviders": "site",
            "newUser": true,
            "sessionInfo": {
                "cookieName": "gac_3_GMmr9ZK67WjXiMpgyNPZquSXUppR7k5CTPe6LCAM32p2sxz6fzUc_IaadjyDzVH4",
                "cookieValue": "st2.s.AcbHOWBfLg.K0SnY0--oMkBWgeWKC0JTEK-NM8S_CSa4uNOgujjwdfAK1Dasbl1XI2D6LTrq_vpKu3LADrYuoe87KVz3hvS6YuetyWLCycrmwBXNuszbto.tj3ReOkNAVMmpfrwjXwAHRUs-gmPuQwPX5vqAKiSygxNic48Q_3CSxWbsCyr-Od6BFe3QElTzw3wUDkiDGzlWg.sc3"
            }
        }
     */
    async register(params: RegisterUserParams): Promise<any | null> {
        const {regToken} = await this.initRegistration();
        const {email, password} = params;
        let body = {
            apiKey: this.apiKey,
            secret: this.apiSecret,
            userKey: this.userKey,
            regToken,
            email,
            password,
            profile: JSON.stringify({
                email: params.email,
                country: params.country,
                firstName: params.firstName,
                lastName: params.lastName,
                birthDay: params.birthDay,
                birthMonth: params.birthMonth,
                birthYear: params.birthYear,
                ...(!!params.zip ? {zip: params.zip} : {}),
            }),
            finalizeRegistration: true,
            subscriptions: JSON.stringify({
                "global.email": {
                    isSubscribed: params.optIn,
                },
                "nfl_fantasy.email": {
                    isSubscribed: false,
                },
                "team_recap.email": {
                    isSubscribed: false,
                },
                "nfl_products.email": {
                    isSubscribed: false,
                },
                "daily_newsletter.email": {
                    isSubscribed: false,
                },
                "nfl_news.email": {
                    isSubscribed: false,
                },
                "nfl_programming.email": {
                    isSubscribed: false,
                },
                "nfl_network_weekly.email": {
                    isSubscribed: false,
                },
                "nfl_partner_offers.email": {
                    isSubscribed: false,
                },
                "nfl_events.email": {
                    isSubscribed: false,
                },
                "nfl_network_program.email": {
                    isSubscribed: false,
                },
                "nfl_mobile.email": {
                    isSubscribed: false,
                },
                "nfl_game_pass.email": {
                    isSubscribed: false,
                },
            }),
        };
        // @ts-expect-error FixMe
        body = encodeBody(body);
        return this.fetch({
            path: "accounts.register",
            body,
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }

    async initRegistration(): Promise<any | null> {
        let body = {
            apiKey: this.apiKey,
            secret: this.apiSecret,
            userKey: this.userKey,
        };
        // @ts-expect-error FixMe
        body = encodeBody(body);
        return this.fetch({
            path: "accounts.initRegistration",
            body,
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }

    async finalizeRegistration(): Promise<any | null> {
        const useData = await this.fetch({
            path: "accounts.finalizeRegistration",
            params: {
                regToken: this.regToken,
            },
            method: "POST",
        });
        if (useData?.UID) {
            this.uid = useData.UID;
        }
        return useData;
    }

    logout(): Promise<void> {
        this.uid = "";
        return Promise.resolve();
    }

    getConflictingAccount(_regToken: string): Promise<void> {
        return Promise.resolve();
    }

    forgotPassword(_email: String): Promise<void> {
        return Promise.resolve();
    }

    async exchangeUIDSignature(
        params: {
            uid?: string;
            uidSignature?: string;
            signatureTimestamp?: string;
        } | null
    ): Promise<any> {
        const {uid, uidSignature, signatureTimestamp} = params || {};

        if (!uid || !uidSignature || !signatureTimestamp) {
            return null;
        }
        return this.fetch({
            path: "accounts.exchangeUIDSignature",
            params: {
                apiKey: this.apiKey,
                secret: this.apiSecret,
                userKey: this.userKey,
            },
            body: encodeBody({
                apiKey: this.apiKey,
                secret: this.apiSecret,
                signatureTimestamp,
                UID: uid,
                UIDSignature: uidSignature,
                userKey: this.userKey,
            }),
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
        });
    }

    async store(type: string, contentId: string, UID: string, data: string) {
        const body = encodeBody({
            data,
            type,
            oid: contentId,
            UID,
        });
        const params = {
            apiKey: this.apiKey,
            secret: this.apiSecret,
            userKey: this.userKey,
        };
        return this.fetch({
            path: "ds.store",
            params,
            body,
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
            domain: "ds",
        });
    }
}
