type TokenRequest = () => Promise<NFLTokenType>;

type Listener = () => {};

class NFLAPIConfigService {
    articleBaseUrl: string | null | undefined;

    baseUrl: string;

    authTokenBaseUrl: string;

    getAuthToken: TokenRequest;

    listeners = new Set<Listener>();

    register({
        articleBaseUrl,
        authTokenBaseUrl,
        baseUrl,
        getAuthToken,
    }: {
        articleBaseUrl?: string | null | undefined;
        authTokenBaseUrl?: string;
        baseUrl?: string;
        getAuthToken?: TokenRequest;
    }) {
        if (articleBaseUrl) this.articleBaseUrl = articleBaseUrl;
        if (authTokenBaseUrl) this.authTokenBaseUrl = authTokenBaseUrl;
        if (baseUrl) this.baseUrl = baseUrl;
        if (getAuthToken) this.getAuthToken = getAuthToken;

        this.listeners.forEach((listener) => listener());
    }

    onUpdated(listener: Listener) {
        this.listeners.add(listener);
        return () => {
            this.listeners.delete(listener);
        };
    }
}

export const NFLAPIConfig = new NFLAPIConfigService();
