import "whatwg-fetch";

import React from "react";
import {createRoot} from "react-dom/client";
import {NFLAPIConfig} from "@nfl/nfl-api/src/NFLAPIConfig/NFLAPIConfig";
import {storage} from "@nfl/rn-shared/src/stores/localStorage";
import {useNetInfo} from "@react-native-community/netinfo";

// constants
import {ENDPOINTS} from "../constants/api";
import {ROUTES} from "../constants/nav";

// types
import {UserType} from "../types/index.d";

// utils
import {getNFLAuthTokenConfig} from "../utils/getNFLToken";
import {fetchFantasyApi, fetchPublicInfo} from "../utils/sdk";
import {getStoredValue, storeValue} from "../utils/sessionStorage";
import {matchUrlParams, parseQuery} from "../utils/url";
import {
    defaultUserState,
    getInitialUserState,
    getSelectedLeague,
    getUserState,
} from "../utils/user";
import UserContext from "../components/UserContext";
import NavHeader from "../components/NavHeader";

require("../public/static/styles/fantasy-ui-nav.css");

// @ts-ignore
window.NFL_FANTASY_NAV = (selector, forceLeagueId) => {
    const NavWrapper = () => {
        useNetInfo({reachabilityShouldRun: () => false});
        const [userState, setUserState] = React.useState<{
            user: UserType;
            fetched: boolean;
        }>({
            user: defaultUserState,
            fetched: false,
        });
        const [pageName, setPageName] = React.useState("");
        const [pageParams, setPageParams] =
            React.useState<Record<string, string>>();
        const leagueId = forceLeagueId || pageParams?.leagueId;

        React.useEffect(() => {
            const {pathname, search} = document.location;
            const query = parseQuery(search.substring(1));
            if (query.leagueId === "0") {
                delete query.leagueId;
            }
            const route = Object.keys(ROUTES).find((key) => {
                const params = matchUrlParams(ROUTES[key].path, pathname);
                if (!params) return false;
                Object.assign(query, params);
                return true;
            });
            if (route) setPageName(route);
            setPageParams(query);
            NFLAPIConfig.register({
                articleBaseUrl: null,
                baseUrl: process.env.NFL_API_PATH,
                getAuthToken: getNFLAuthTokenConfig({
                    userState: {status: "USER_START"},
                }),
            });
            fetchPublicInfo().then((data) => {
                const country = data?.headers?.nfl_geo_country;
                if (country) {
                    // the video player depends on this info for targeting ads. https://jira.dm.nfl.com/browse/FTSY-6376
                    storage.save({
                        key: "user.countryCode",
                        data: country,
                        expires: null,
                    });
                }
            });
            const initialUser = getInitialUserState();
            if (initialUser) setUserState({user: initialUser, fetched: true});
            getUserState().then((user) => {
                setUserState({user, fetched: true});
            });
        }, []);
        React.useEffect(() => {
            const {isLoggedIn, leagues} = userState.user || {};
            const league = isLoggedIn && getSelectedLeague(leagues, leagueId);
            if (!league) return;
            const {id} = league;
            const key = `settings-${id}`;
            const settings = getStoredValue(key);
            (settings
                ? Promise.resolve(settings.numKeeperPlayers)
                : fetchFantasyApi(ENDPOINTS.GET_LEAGUE_SETTINGS, {
                      firstGame: true,
                      query: {leagueId: id},
                  }).then((game) => {
                      const {numKeeperPlayers} = game.leagues[id].settings;
                      storeValue(key, {numKeeperPlayers});
                      return numKeeperPlayers;
                  })
            ).then((numKeeperPlayers) => {
                league.numKeeperPlayers = +(numKeeperPlayers || 0);
                setUserState((state) => ({...state}));
            });
        }, [userState.user, leagueId]);
        const context = React.useMemo(
            () => ({...userState, refresh: () => Promise.resolve()}),
            [userState]
        );
        return (
            <UserContext.Provider value={context}>
                <NavHeader
                    location={document.location.href}
                    pageName={pageName}
                    pageParams={pageParams}
                />
            </UserContext.Provider>
        );
    };

    createRoot(document.querySelector(selector)).render(<NavWrapper />);

    // TODO: remove
    return {refreshDisplay: () => {}};
};
