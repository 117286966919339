import cookie from "react-cookies";

import {getHostName} from "./url";

export const getCookieName = () => {
    const hostname = getHostName();
    if (String(hostname).indexOf("ffsim") > -1) {
        return "ffsim";
    }
    return process.env.FANTASY_COOKIE_NAME;
};

export const getCookieValue = (name: string | undefined) => {
    if (!name) return "";

    const re = new RegExp(`${name}=([^;]+)`);
    const value = re.exec(document.cookie);
    return value != null ? unescape(value[1]) : "";
};

export const removeFantasyCookie = () => {
    const scope = {path: "/", domain: ".nfl.com"};
    cookie.remove(getCookieName(), scope);
    cookie.remove("fana", scope);
};
