import {storage} from "./mmkv";

export const GUID_STORAGE_KEY = "nfl.login.guid";
let localGUID: string | null = null;

export function generateGUID() {
    let d = Date.now();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        (c) => {
            const r = (d + Math.random() * 16) % 16 | 0; // eslint-disable-line no-bitwise
            d = Math.floor(d / 16);
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16); // eslint-disable-line no-bitwise
        }
    );
    return uuid;
}

export const getGUID = (storageKey: string = GUID_STORAGE_KEY) => {
    if (localGUID) {
        return localGUID;
    }
    localGUID = storage.getString(storageKey) || null;
    if (!localGUID) {
        localGUID = generateGUID();
        storage.set(storageKey, localGUID || "");
    }
    return localGUID;
};

export const _setGUID = async (guid) => {
    if (!guid) {
        return;
    }
    const storageGUID = await storage.getString(GUID_STORAGE_KEY);
    if (!storageGUID || storageGUID !== guid) {
        storage.set(GUID_STORAGE_KEY, guid || "");
    }
};
