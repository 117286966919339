/**
 * Used on the client side.  Calls the tracking for analytics.
 *
 * @type {any}
 */
import {TRACKING_SITE_NAME} from "../constants/tracking";

export const initializeTracking = () => {
    if (window?._satellite?.track && window.fantasyTrackingLoaded) {
        return;
    }
    const script = document.createElement("script");
    script.src = process.env.TRACKING_SRC_URL;
    script.async = true;
    script.onload = () => {
        window.fantasyTrackingLoaded = true;
    };
    document.body.appendChild(script);
};

// biome-ignore lint/style/useDefaultParameterLast: working as-is
export const trackPageView = (siteSubsection = "landing", user) => {
    const data = {
        page: {
            siteName: "nfl fantasy",
            siteSection: "home",
            pageDetail: "landing",
            siteLanguage: "english",
            optimizedFor: "desktop",
            contentType: "index",
        },
        user: {},
    };
    if (user?.isLoggedIn) {
        data.user = {
            userID: user.userId,
        };
        const league = user.leagues?.length ? user.leagues[0] : null;
        if (league) {
            data.user.leagueID = league.id;
            data.user.leagueType = league.leagueType;
            data.user.leagueManager = league.isManager
                ? "commissioner privileged"
                : "non-commissioner";
            data.user.playerType = league.isManager
                ? "league owner"
                : "non-league owner";
            data.user.teamID = league.teamId;
            data.user.currentStandings = league.teamRank
                ? `${league.teamRank}:${league.numTeams}`
                : "";
        }
    }

    data.page.siteSubsection = siteSubsection;
    data.page.pageName = `${data.page.siteName}:${data.page.siteSection}:${data.page.siteSubsection}:${data.page.pageDetail}`;
    if (window?._satellite?.track && window.fantasyTrackingLoaded) {
        window._satellite.track("virtual_pv", data);
    } else {
        window.analyticsData = data;
        initializeTracking();
    }
    if (siteSubsection === "home") {
        try {
            window?.kochava?.page?.();
        } catch (e) {
            console.error("Kochava page call failed", e);
        }
    }
};

export const trackClick = (type, obj) => {
    if (window?._satellite?.track) {
        if (type && obj) {
            window._satellite.track(type, {
                ...obj,
                siteName: TRACKING_SITE_NAME,
            });
        }
    }
};
