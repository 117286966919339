import {Platform} from "react-native";
import {
    NFLRefreshableToken,
    NFLTokenRequestType,
} from "@nfl/nfl-api/src/NFLToken/NFLRefreshableToken";

// utils
import {getCurrentLocation} from "./geolocation";
import {getDeviceInfo} from "./thirdPartyAuthUtils";

const LOCATION_REQUEST_TIMEOUT = 3000; // milliseconds
const isWeb = Platform.OS === "web";

const shapeRequestParams = async (tokenRequestParams: NFLTokenRequestType) => {
    const isWebSSR = isWeb && typeof window === "undefined";

    const locationPromise = isWeb
        ? (tokenRequestParams.requireLocation &&
              getCurrentLocation().catch((error) => error)) ||
          null
        : getCurrentLocation({
              enableHighAccuracy: false,
              skipPermissionRequests: tokenRequestParams.skipPermissionRequests,
              timeout: LOCATION_REQUEST_TIMEOUT,
          }).catch(() => null);

    const [location, {deviceInfo}] = await Promise.all([
        tokenRequestParams.customLocation || locationPromise,
        !isWebSSR ? getDeviceInfo() : ({} as any),
    ]);

    const claims = NFLRefreshableToken.claimsFromParams({
        amazonPrimeUserId: tokenRequestParams.amazonPrimeUserId,
        espnPlusUserId: tokenRequestParams.espnPlusUserId,
        mvpdUserId: tokenRequestParams.mvpdUserId,
        paramountPlusUserId: tokenRequestParams.paramountPlusUserId,
        peacockUserId: tokenRequestParams.peacockUserId,
        uid: tokenRequestParams.uid,
        youTubeUserId: tokenRequestParams.youTubeUserId,
    });

    const finalRequestParams = isWeb
        ? {...tokenRequestParams, location: location || undefined}
        : {
              ...tokenRequestParams,
              deviceInfo,
              location: location || undefined,
          };

    return {
        claims,
        finalRequestParams,
    };
};

export const fetchRefreshableNFLToken = async (
    tokenRequestParams: NFLTokenRequestType
): Promise<NFLTokenType> => {
    const {claims, finalRequestParams} =
        await shapeRequestParams(tokenRequestParams);

    return NFLRefreshableToken.getToken(finalRequestParams).then((token) => ({
        ...token,
        claims,
    }));
};

export const fetchRefreshableNFLRefreshToken = async (
    tokenRequestParams: NFLTokenRequestType
): Promise<NFLTokenType> => {
    const {claims, finalRequestParams} =
        await shapeRequestParams(tokenRequestParams);

    return NFLRefreshableToken.refreshToken(finalRequestParams).then(
        (token) => ({
            ...token,
            claims,
        })
    );
};
