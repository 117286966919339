export type CTVDevices =
    | "AppleTV"
    | "AndroidTV"
    | "FireTV"
    | "lg"
    | "samsung"
    | "vizio"
    | "xbox";

export type DeviceInfoType = {
    analyticsAppName?: string;
    capabilities?: any;
    ctvDevice?: CTVDevices;
    diskCapacity?: number;
    displayHeight?: number;
    displayWidth?: number;
    idfv?: string;
    isLocationEnabled?: boolean;
    manufacturer?: string;
    memory?: number;
    model?: string;
    osName?: string;
    osVersion?: string;
    vendor?: string;
    version?: string;
    versionName?: string;
    networkType?: string;
};

export type DeviceInfoState = {
    b64?: string | undefined;
    deviceInfo: DeviceInfoType;
    deviceType: "Tablet" | "MobilePhone";
};

export enum AudioGuidanceStatus {
    ON = "on",
    OFF = "off",
}
